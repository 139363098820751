import Dexie, { Table } from "dexie";
import { IActivity } from "../types";
export interface Audio {
  activityId: string;
  activityType: string;
  description: string;
  uploaded: Boolean;
  audio: ArrayBuffer |null;
  week: Date; // 2022-02-21
  recordingOrder?: number;
}

export interface Activity extends IActivity {}

export class AppDexie extends Dexie {
  audios!: Table<Audio>;
  activities!: Table<Activity>;
  constructor() {
    super("speechbox_db");
    this.version(1).stores({
      audios: "activityId", // Primary key and indexed props
      activities: "activityId",
    });
  }
}

export const db = new AppDexie();

export const dbSaveAudio = async (payload: Audio) => {
  console.log("---dbSaveAudio.1.................",payload);
  
  const found = await db.audios.get({ activityId: payload.activityId });
  if (found) {
    // delete recording
    await db.audios.delete(found.activityId);
  }
  const saved = await db.audios.toArray();
  const count = saved.length + 1;
  payload.recordingOrder = count;
  return db.audios.add(payload);
};

export const dbBulkUpsertActivities = async (payload: Activity[]) => {
  console.log("dbBulkUpsertActivities..2................",payload);

  // see if the activity is there, if no insert
  for (const activity of payload) {
    const present = await db.activities.get({
      activityId: activity.activityId,
    });
    if (!present) {
      await db.activities.add(activity);
    }
  }
  return true;
};

export const dbGetActivity = (activityId: string) => {
  console.log("dbGetActivity..3................",activityId);

  return db.activities.get({ activityId });
};

export const dbGetNextToRecord = async () => {
  console.log("dbGetNextToRecord..4................");

  // check all recordings done
  const saved = await db.audios.toArray();
  const savedActivityIds = saved.map((s) => s.activityId);

  const allActivities = await db.activities.toArray();
  const allActivityIds = allActivities.map((a) => a.activityId);

  const rem = allActivityIds.filter((a) => !savedActivityIds.includes(a));
  if (rem.length) {
    return rem[0];
  }
  return "";
};

export const dbSavedCount = async () => {
  console.log("dbSavedCount..5................");

  const saved = await db.audios.toArray();
  console.log("dbSavedCount..................",saved);
  return saved.length;
};

export const dbSavedAudios = async () => {
  console.log("dbSavedAudios.6................");
  return await db.audios.toArray();
};

export const dbNuke = async () => {
  console.log("dbNuke....7..............");
  await db.audios.clear();
  await db.activities.clear();
  return;
};

export const db_audioNuke = async () => {
  console.log("db_audioNuke...8...............");
  await db.audios.clear();
  return;
};