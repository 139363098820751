import { useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import useAuth from "../contexts/AuthContext";

import { timeAgo } from "../services/utils";

interface NotificationToastsProps {}

const NotificationToasts: React.FC<NotificationToastsProps> = () => {
  const { state: authState, dispatch: authDispatch } = useAuth();

  useEffect(() => {
    if (authState.notifications.length) {
      window.scrollTo(0, 0);
    }
  }, [authState.notifications]);

  return (
    <>
      <ToastContainer position="top-end" className="p-3 max-z-index">
        {authState.notifications.map((n, index) => {
          return (
            <Toast
              key={`notification--${n.id}`}
              onClose={() =>
                authDispatch({ type: "REMOVE_NOTIFICATION", value: index })
              }
              bg={n.notificationType}
            >
              <Toast.Header>
                <strong className="me-auto">{n.heading}</strong>
                <small>{timeAgo(n.datetime)}</small>
              </Toast.Header>
              <Toast.Body>{n.text}</Toast.Body>
            </Toast>
          );
        })}
      </ToastContainer>
    </>
  );
};

export default NotificationToasts;
