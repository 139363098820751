import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";

// components
import Loading from "./components/Loading";
import PrivateRoute from "./components/PrivateRoute";
import NotificationToasts from "./components/NotificationToasts";
import LoadingToast from "./components/LoadingToast";

// pages
import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";

// contexts
import { AuthProvider } from "./contexts/AuthContext";
import { ActivitiesProvider } from "./contexts/ActivitiesContext";
import { MediaStreamProvider } from "./contexts/MediaStreamContext";
import { AudioAnalyserProvider } from "./contexts/AudioAnalyserContext";
import { InputAudioProvider } from "./contexts/InputAudioContext";

// all other pages need to be lazy loaded
import PrivacyPolicy from "./pages/PrivacyPolicy";
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Activities = lazy(() => import("./pages/Activities"));
const ActivitiesRecord = lazy(() => import("./pages/ActivitiesRecord"));
const ActivitiesNext = lazy(() => import("./pages/ActivitiesNext"));
const Contact = lazy(() => import("./pages/Contact"));
const Account = lazy(() => import("./pages/Account"));
const Questionnaire = lazy(() => import("./pages/Questionnaire"));
const Upload = lazy(() => import("./pages/Upload"));
const AboutTheResearch = lazy(() => import("./pages/AboutResearch"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const MicCheck = lazy(() => import("./pages/MicCheck"));

function App() {
  return (
    <MediaStreamProvider video={false} audio={true}>
      <InputAudioProvider>
        <AudioAnalyserProvider>
          <AuthProvider>
            <ActivitiesProvider>
              <BrowserRouter>
                <NotificationToasts />
                <LoadingToast />
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route path="/auth" element={<Auth />} />
                    <Route
                      path="/auth/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/auth/reset-password"
                      element={<ResetPassword />}
                    />
                    <Route
                      path="/"
                      element={<PrivateRoute outlet={<Dashboard />} />}
                    />
                    <Route
                      path="/activities"
                      element={<PrivateRoute outlet={<Activities />} />}
                    />
                    <Route
                      path="/activities/record/:activityId"
                      element={<PrivateRoute outlet={<ActivitiesRecord />} />}
                    />
                    <Route
                      path="/activities/next"
                      element={<PrivateRoute outlet={<ActivitiesNext />} />}
                    />
                    <Route
                      path="/activities/questionnaire"
                      element={<PrivateRoute outlet={<Questionnaire />} />}
                    />
                    <Route
                      path="/activities/upload"
                      element={<PrivateRoute outlet={<Upload />} />}
                    />
                    <Route
                      path="/account"
                      element={<PrivateRoute outlet={<Account />} />}
                    />
                    <Route
                      path="/about-the-research"
                      element={<AboutTheResearch />}
                    />
                    <Route path="/account/mic-check" element={<MicCheck />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </BrowserRouter>
            </ActivitiesProvider>
          </AuthProvider>
        </AudioAnalyserProvider>
      </InputAudioProvider>
    </MediaStreamProvider>
  );
}

export default App;
