import axios, { AxiosResponse, CancelToken } from "axios";
import { Dispatch } from "react";
import { TAuthAction } from "../reducers/AuthReducer";
import config from "./config";

const http = axios.create({
  baseURL: `${config.apiUrl}${config.apiBase}`,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
});

// setting new access token if the one we got from signup
// has expired
export const setupResponseInterceptor = (
  authDispatch: Dispatch<TAuthAction>
) => {
  http.interceptors.response.use((res: AxiosResponse) => {
    if (res && res.headers["x-access-token"]) {
      authDispatch({
        type: "SET_ACCESS_TOKEN",
        value: res.headers["x-access-token"],
      });
    }
    return res;
  });
};

export const setAuthHeaders = ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  if (accessToken) {
    http.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  } else {
    delete http.defaults.headers.common["Authorization"];
  }
  if (refreshToken) {
    http.defaults.headers.common["x-refresh"] = `${refreshToken}`;
  } else {
    delete http.defaults.headers.common["x-refresh"];
  }
  return;
};

export default http;

// All endpoints are mentioned in this one file as the app is tiny.
interface ILogin {
  userId: string;
  password: string;
}
export interface ILoginSuccess {
  accessToken: string;
  refreshToken: string;
}
export const apiLogin = (payload: ILogin, cancelToken: CancelToken) => {
  return http.post("/sessions", payload, { cancelToken: cancelToken });
};

export const apiForgotPassword = (
  payload: { userId: string },
  cancelToken: CancelToken
) => {
  return http.post("/users/forgot-password", payload, {
    cancelToken: cancelToken,
  });
};

export const apiResetPassword = (
  payload: { userId: string; resetCode: string; password: string },
  cancelToken: CancelToken
) => {
  return http.post("/users/reset-password", payload, {
    cancelToken: cancelToken,
  });
};

export const apiGetUserInfo = (cancelToken: CancelToken) => {
  return http.get("/users", { cancelToken: cancelToken });
};

// the user wont be navigating to other pages
// if they give a logout request, hence the reason why this
// request does not have a cancel token
export const apiLogout = () => {
  return http.delete("/sessions");
};

interface ICheckInvite {
  inviteCode: string;
}
export const apiCheckInviteCode = (
  payload: ICheckInvite,
  cancelToken: CancelToken
) => {
  return http.get(`/users/invite/${payload.inviteCode}`, {
    cancelToken: cancelToken,
  });
};

interface ISignup extends ILogin {
  inviteCode: string;
}

export const apiSignup = (payload: ISignup, cancelToken: CancelToken) => {
  return http.put(
    `/users/invite/${payload.inviteCode}`,
    {
      userId: payload.userId,
      password: payload.password,
    },
    { cancelToken: cancelToken }
  );
};

export const apiGetWeekActivities = (cancelToken: CancelToken) => {
  return http.get("/activities/user/week", { cancelToken: cancelToken });
};

interface IRecording {
  activityId: string;
  file: File;
  recordingOrder?: string;
}

export const apiSendRecording = (
  payload: IRecording,
  cancelToken: CancelToken
) => {
  const formData = new FormData();
  formData.append("audio", payload.file);
  if (payload.recordingOrder) {
    formData.append("recordingOrder", payload.recordingOrder);
  }
  return http.post(`/activities/user/${payload.activityId}`, formData, {
    cancelToken: cancelToken,
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

interface IQuestionnaire {
  week: Date;
  interrupted: Boolean;
  anyoneElse: Boolean;
}

export const apiAddQuestionnaireResponse = (
  payload: IQuestionnaire,
  cancelToken: CancelToken
) => {
  return http.post(`/questionnaires/response`, payload, {
    cancelToken: cancelToken,
  });
};

export const apiGetPastActivities = (cancelToken: CancelToken) => {
  return http.get("/activities/user/past", { cancelToken: cancelToken });
};

export const apiUpdatePassword = (
  payload: { password: string },
  cancelToken: CancelToken
) => {
  return http.put(`/users/password`, payload, {
    cancelToken: cancelToken,
  });
};

export const apiDeleteAccount = (cancelToken: CancelToken) => {
  return http.delete(`/users/account`, {
    cancelToken: cancelToken,
  });
};
