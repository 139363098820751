type TConfig = {
  appName: string;
  apiUrl: string;
  apiBase: string;
  recordingDuration: number;
  countDownDuration: number;
};

const config: TConfig = {
  appName: process.env.REACT_APP_NAME || "Speechbox",
  apiUrl: process.env.REACT_APP_API || "",
  apiBase: process.env.REACT_APP_API_BASE || "/api/v01",
  recordingDuration: Number(process.env.REACT_APP_RECORDING_DURATION),
  countDownDuration: Number(process.env.REACT_APP_COUNTDOWN_DURATION),
};

if (!config.recordingDuration) {
  config.recordingDuration = 10;
}
if (!config.countDownDuration) {
  config.countDownDuration = 3;
}

export default config;
