import React, { useEffect } from "react";

import Container from "./Container";
import Footer from "./Footer";
import { MenuBar } from "./MenuBar";
import Header from "../components/Header";
import useAuth from "../contexts/AuthContext";

import config from "../services/config";

interface PageProps {
  title: string | null;
  heading?: string;
  subText?: string;
  noBg?: Boolean;
}

const Page: React.FC<PageProps> = (props) => {
  const { state: authState } = useAuth();

  useEffect(() => {
    document.title = `${props.title} | ${config.appName}`;
    window.scrollTo(0, 0);
  }, [props.title]);

  return (
    <>
      <div className="">
        <Container noBg={props.noBg}>
          <Header title={props.heading} subText={props.subText} />

          {/* where all page component content renders */}
          <div className="inner-container">{props.children}</div>

          {authState.isLoggedIn && <MenuBar />}
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Page;
