import Page from "../components/Page";
import { Link } from "react-router-dom";

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <Page title="Page not found" heading="Page not found">
      <p>
        <Link to="/">Go to main page</Link>
      </p>
    </Page>
  );
};

export default NotFound;
