import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import { apiLogout, setupResponseInterceptor } from "../services/api";
import { IUser } from "../types";
import {
  authReducer,
  initialState,
  TAuthAction,
  IAuthState,
} from "../reducers/AuthReducer";
import { setAuthHeaders, apiGetUserInfo } from "../services/api";
import {
  storageDeleteAccessToken,
  storageDeleteMicCheck,
  storageDeleteRefreshToken,
  storagePutAccessToken,
  storagePutMicCheck,
  storagePutMicCheckDate,
  storagePutRefreshToken,
  storageDeleteMicCheckDate,
} from "../services/storage";
import { getAxiosError, buildNotification } from "../services/utils";

type AuthContextProps = {
  state: IAuthState;
  dispatch: React.Dispatch<TAuthAction>;
};

const AuthContext = createContext<AuthContextProps>({
  state: initialState,
  dispatch: () => initialState,
});

export function AuthProvider(props: PropsWithChildren<{}>) {
  const [state, dispatch] = useImmerReducer(authReducer, initialState);

  const logoutCB = useCallback(() => {
    async function doLogout() {
      try {
        await apiLogout();
        dispatch({
          type: "ADD_NOTIFICATION",
          value: buildNotification({
            text: "You've successfully logged out",
            notificationType: "success",
            heading: "Bye",
          }),
        });
      } catch (e) {
        // TODO: dont know what to do here. We dont want to show the user
        // that the logout request failed though
        console.log(e);
      }
    }

    if (!state.isLoggedIn) {
      if (state.accessToken && state.refreshToken) {
        doLogout();
      }
    }
  }, [state.isLoggedIn, state.accessToken, state.refreshToken, dispatch]);

  // mic check
  useEffect(() => {
    if (state.checkedMic) {
      storagePutMicCheck("yes");
    } else {
      storageDeleteMicCheck();
    }
  }, [state.checkedMic]);

  // mic check date
  useEffect(() => {
    if (state.checkedMic && state.micCheckedOn) {
      storagePutMicCheckDate(state.micCheckedOn);
    } else {
      storageDeleteMicCheckDate();
    }
  }, [state.checkedMic, state.micCheckedOn]);

  // clearing notifications one after the other after 5 seconds
  useEffect(() => {
    if (state.notifications.length) {
      const delay = setTimeout(() => {
        dispatch({ type: "REMOVE_NOTIFICATION", value: -1 });
      }, 5000);

      return () => clearTimeout(delay);
    }
  }, [state.notifications, dispatch]);

  // hooking axios responses
  useEffect(() => {
    if (state.isLoggedIn) {
      setupResponseInterceptor(dispatch);
    }
  }, [state.isLoggedIn, dispatch]);

  // inital auth check
  useEffect(() => {
    const request = axios.CancelToken.source();
    async function getUserInfo() {
      try {
        const { data } = await apiGetUserInfo(request.token);
        const userInfo = data.user as IUser;
        dispatch({ type: "LOAD_USER", value: userInfo });
      } catch (e) {
        console.log(e);
        dispatch({
          type: "ADD_NOTIFICATION",
          value: buildNotification({
            text: getAxiosError(e),
            notificationType: "danger",
            heading: "Request Error",
          }),
        });
        // TODO: can this be handled differently?
        dispatch({
          type: "LOGOUT",
        });
      }
    }

    if (state.isLoggedIn && state.accessToken && state.refreshToken) {
      storagePutAccessToken(state.accessToken);
      storagePutRefreshToken(state.refreshToken);
      setAuthHeaders({
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
      });
      getUserInfo();
      return () => request.cancel();
    } else {
      if (state.accessToken || state.refreshToken) {
        logoutCB();
      }
      storageDeleteAccessToken();
      storageDeleteRefreshToken();
      dispatch({ type: "SET_MIC_CHECK", value: false });
      dispatch({ type: "SET_MIC_CHECK_DATE", value: "" });
      dispatch({ type: "CLEAR_SESSION" });
      setAuthHeaders({ accessToken: "", refreshToken: "" });
    }
  }, [
    state.isLoggedIn,
    state.accessToken,
    state.refreshToken,
    dispatch,
    logoutCB,
  ]);

  return <AuthContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function useAuth() {
  return useContext(AuthContext);
}
