import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import '../styles/MenuBar.css';
import Account from '../assets/svg/account.svg';
import AccountActive from '../assets/svg/account-active.svg';
import Activities from '../assets/svg/activities.svg';
import ActivitiesActive from '../assets/svg/activities-active.svg';
import Contact from '../assets/svg/contact.svg';
import ContactActive from '../assets/svg/contact-active.svg';

interface MenuBarProps {}

export const MenuBar: React.FC<MenuBarProps> = () => {
  const location = useLocation();
  return (
    <div className="menu-group menubar-fix">
      <Link to="/account">
        {location.pathname === '/account' ? (
          <img src={AccountActive} alt="Account setting" />
        ) : (
          <img src={Account} alt="Account setting" />
        )}
        <div>Account</div>
      </Link>

      <Link to="/activities">
        {location.pathname === '/activities' ? (
          <img src={ActivitiesActive} alt="Activities" />
        ) : (
          <img src={Activities} alt="Activities" />
        )}
        <div>Activities</div>
      </Link>

      <Link to="/contact">
        {location.pathname === '/contact' ? (
          <img src={ContactActive} alt="Contact" />
        ) : (
          <img src={Contact} alt="Contact" />
        )}
        <div>Contact</div>
      </Link>
    </div>
  );
};
