import produce from "immer";
import moment from "moment";
import { IActivity, IPastActivity } from "../types";
import { Audio } from "../services/db";

export type TActivitiesAction =
  | {
      type: "SET_ACTIVITIES_TO_ATTEMPT";
      value: IActivity[];
    }
  | {
      type: "SET_ACTIVITIES_ATTEMPTED";
      value: IActivity[];
    }
  | {
      type: "SET_WEEK_START";
      value: Date;
    }
  | {
      type: "TOGGLE_FETCH";
    }
  | {
      type: "SET_NEXT_ACTIVITY";
      value: IActivity;
    }
  | {
      type: "LOAD_AUDIOS_IN_DB";
      value: Audio[];
    }
  | {
      type: "SET_SEND_AUDIOS";
      value: Boolean;
    }
  | {
      type: "MARK_AUDIO_UPLOADED";
      value: string; // will be the activityId
    }
  | {
      type: "TOGGLE_PAST_FETCH";
    }
  | {
      type: "SET_PAST_ACTIVITIES";
      value: IPastActivity[];
    }
  | {
      type: "RESET";
    };

export interface IActivitiesState {
  activitiesToAttempt: IActivity[];
  activitiesAttempted: IActivity[];
  startOfWeek: Date;
  fetchForWeek: Boolean;
  nextActivity: IActivity | null;
  audiosInDB: Audio[];
  sendAudios: Boolean;
  fetchPast: Boolean;
  pastActivities: IPastActivity[];
  uploadedActivities: string[]; // activityIds
}

export const initialState: IActivitiesState = {
  activitiesToAttempt: [],
  activitiesAttempted: [],
  startOfWeek: moment().startOf("isoWeek").toDate(),
  fetchForWeek: false,
  nextActivity: null,
  audiosInDB: [],
  sendAudios: false,
  fetchPast: false,
  pastActivities: [],
  uploadedActivities: [],
};

export function activitiesReducer(
  state: IActivitiesState,
  action: TActivitiesAction
): IActivitiesState {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_ACTIVITIES_TO_ATTEMPT": {
        draft.activitiesToAttempt = action.value;
        break;
      }
      case "SET_ACTIVITIES_ATTEMPTED": {
        draft.activitiesAttempted = action.value;
        break;
      }
      case "SET_WEEK_START": {
        draft.startOfWeek = action.value;
        break;
      }
      case "TOGGLE_FETCH": {
        draft.fetchForWeek = !draft.fetchForWeek;
        break;
      }
      case "SET_NEXT_ACTIVITY": {
        draft.nextActivity = action.value;
        break;
      }
      case "LOAD_AUDIOS_IN_DB": {
        draft.audiosInDB = action.value;
        break;
      }
      case "SET_SEND_AUDIOS": {
        draft.sendAudios = action.value;
        break;
      }
      case "MARK_AUDIO_UPLOADED": {
        draft.uploadedActivities.push(action.value);
        break;
      }
      case "TOGGLE_PAST_FETCH": {
        draft.fetchPast = !draft.fetchPast;
        break;
      }
      case "SET_PAST_ACTIVITIES": {
        draft.pastActivities = action.value;
        break;
      }
      case "RESET": {
        draft.activitiesToAttempt = [];
        draft.activitiesAttempted = [];
        draft.startOfWeek = moment().startOf("isoWeek").toDate();
        draft.fetchForWeek = false;
        draft.nextActivity = null;
        draft.audiosInDB = [];
        draft.sendAudios = false;
        draft.fetchPast = false;
        draft.pastActivities = [];
        draft.uploadedActivities = [];
        break;
      }
      default:
        break;
    }
    return draft;
  });
}
