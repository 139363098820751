// https://ui.dev/react-router-v5-protected-routes-authentication/
// make sure to redirect the user back to the page they originally
// wanted to visit when auth failed

import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../contexts/AuthContext";

export type PrivateRouteProps = {
  outlet: JSX.Element;
};

const PrivateRoute = ({ outlet }: PrivateRouteProps) => {
  const currentLocation = useLocation();
  const { state: authState, dispatch: authDispatch } = useAuth();

  useEffect(() => {
    if (!authState.isLoggedIn) {
      authDispatch({ type: "SET_REDIRECT", value: currentLocation.pathname });
    }
  }, [authState.isLoggedIn, currentLocation.pathname, authDispatch]);

  if (authState.isLoggedIn) {
    return outlet;
  } else {
    return (
      <Navigate
        to={{ pathname: authState.isLoggedIn ? authState.redirectTo : "/auth" }}
      />
    );
  }
};

export default PrivateRoute;
