import React, { useState } from "react";

import "../styles/TextInput.css";

import { ReactComponent as PasswordEyeOn } from "../assets/svg/Password_Visibility_On.svg";
import { ReactComponent as PasswordEyeOff } from "../assets/svg/Password_Visibility_Off.svg";

interface TextInputProps {
  style?: string;
  text?: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  type: string;
  name?: string;
  addClass?: string;
  inputClass?: string;
  values?: string | number | Array<string>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors?: string | Array<string> | any;
  helpIcon?: boolean;
  helpContent?: string;
  icon?: "search" | "password" | "delete" | "file";
  elementRef?: React.RefObject<HTMLInputElement>;
}

export const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder,
  id,
  disabled,
  type,
  addClass,
  inputClass,
  name,
  errors,
  onBlur,
  values,
  icon,
  onChange,
  elementRef,
}) => {
  const [passwordShown, setPasswordShown] = useState(true);

  return (
    <div className="">
      <div className={`input-groups ${addClass}`}>
        <div className="d-flex input-text-group">
          {label && (
            <label htmlFor={id} className="cursor-pointer">
              {label}
            </label>
          )}
          {icon === "password" && (
            <span
              className="cursor-pointer"
              onClick={() =>
                passwordShown ? setPasswordShown(false) : setPasswordShown(true)
              }
            >
              {passwordShown ? <PasswordEyeOn /> : <PasswordEyeOff />}
            </span>
          )}
        </div>
        <div className="relative">
          <input
            id={id}
            type={
              type === "password"
                ? !passwordShown
                  ? "text"
                  : "password"
                : type
            }
            className={`text-field w-100 ${inputClass || ""} ${
              errors ? "input-error" : ""
            }`}
            placeholder={placeholder}
            disabled={disabled}
            name={name}
            onBlur={onBlur}
            value={values}
            autoComplete="off"
            onChange={onChange}
            ref={elementRef}
          />
        </div>
        {errors && <div className="form-error-msg">{errors}</div>}
      </div>
    </div>
  );
};
