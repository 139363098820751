import React from "react";

interface ButtonProps {
  text?: string;
  addClass?: string;
  buttonType: "submit" | "button";
  disabled?: boolean;
  onClick?: any;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  addClass = "",
  buttonType,
  disabled,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      type={buttonType === "button" ? "button" : "submit"}
      className={`btn ${addClass}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
