// functions that start with __ are local to this file
// and not to be exported

const __ACCESS_TOKEN_KEY__ = "app__access_token";
const __REFRESH_TOKEN_KEY__ = "app__refresh_token";
const __MIC_CHECK_KEY__ = "app__mic_check";
const __MIC_CHECK_DATE_KEY__ = "app__mic_check_date";

const __storageGet = (key: string) => {
  return localStorage.getItem(key);
};

const __storagePut = ({ key, value }: { key: string; value: string }) => {
  localStorage.setItem(key, value);
  return;
};

const __storageDel = (key: string) => {
  return localStorage.removeItem(key);
};

export const storageGetAccessToken = () => __storageGet(__ACCESS_TOKEN_KEY__);

export const storageGetRefreshToken = () => __storageGet(__REFRESH_TOKEN_KEY__);

export const storageDeleteAccessToken = () =>
  __storageDel(__ACCESS_TOKEN_KEY__);

export const storageDeleteRefreshToken = () =>
  __storageDel(__REFRESH_TOKEN_KEY__);

export const storagePutAccessToken = (token: string) => {
  __storagePut({
    key: __ACCESS_TOKEN_KEY__,
    value: token,
  });
  return;
};

export const storagePutRefreshToken = (token: string) => {
  __storagePut({
    key: __REFRESH_TOKEN_KEY__,
    value: token,
  });
  return;
};

export const storageGetMicCheck = () => __storageGet(__MIC_CHECK_KEY__);
export const storagePutMicCheck = (status: string) => {
  __storagePut({
    key: __MIC_CHECK_KEY__,
    value: status,
  });
  return;
};
export const storageDeleteMicCheck = () => __storageDel(__MIC_CHECK_KEY__);

export const storageGetMicCheckDate = () =>
  __storageGet(__MIC_CHECK_DATE_KEY__);
export const storagePutMicCheckDate = (status: string) => {
  __storagePut({
    key: __MIC_CHECK_DATE_KEY__,
    value: status,
  });
  return;
};
export const storageDeleteMicCheckDate = () =>
  __storageDel(__MIC_CHECK_DATE_KEY__);
