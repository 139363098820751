import { produce } from "immer";

import {
  storageGetAccessToken,
  storageGetRefreshToken,
  storageGetMicCheck,
  storageGetMicCheckDate,
} from "../services/storage";

import { IUser, IUserNotification } from "../types";

export type TAuthAction =
  | {
      type: "LOGIN";
      value: {
        accessToken: string;
        refreshToken: string;
      };
    }
  | {
      type: "LOAD_USER";
      value: IUser;
    }
  | { type: "LOGOUT" }
  | {
      type: "CLEAR_SESSION";
    }
  | {
      type: "SET_REDIRECT";
      value: string;
    }
  | {
      type: "SET_ACCESS_TOKEN";
      value: string;
    }
  | {
      type: "ADD_NOTIFICATION";
      value: IUserNotification;
    }
  | {
      type: "REMOVE_NOTIFICATION";
      value: number;
    }
  | {
      type: "SET_MIC_CHECK";
      value: boolean;
    }
  | {
      type: "SET_ACTION_PENDING";
      value: boolean;
    }
  | {
      type: "SET_MIC_CHECK_DATE";
      value: string;
    };
export interface IAuthState {
  isLoggedIn: boolean;
  actionPending: boolean;
  user: IUser;
  accessToken: string | null;
  refreshToken: string | null;
  redirectTo: string;
  notifications: Array<IUserNotification>;
  checkedMic: boolean;
  micCheckedOn: string;
}

export const initialState: IAuthState = {
  isLoggedIn: Boolean(storageGetAccessToken()),
  actionPending: false,
  user: {
    name: "",
    email: "",
    userId: "",
    taskType: "",
  },
  accessToken: storageGetAccessToken(),
  refreshToken: storageGetRefreshToken(),
  redirectTo: "",
  notifications: [],
  checkedMic: Boolean(storageGetMicCheck()),
  micCheckedOn: storageGetMicCheckDate() || "",
};

export function authReducer(
  state: IAuthState,
  action: TAuthAction
): IAuthState {
  return produce(state, (draft) => {
    switch (action.type) {
      case "LOGIN": {
        draft.isLoggedIn = true;
        draft.accessToken = action.value.accessToken;
        draft.refreshToken = action.value.refreshToken;
        break;
      }
      case "LOAD_USER": {
        draft.user = action.value;
        break;
      }
      case "LOGOUT": {
        draft.isLoggedIn = false;
        break;
      }
      case "CLEAR_SESSION":
        draft.user = {
          name: "",
          email: "",
          userId: "",
          taskType: "",
        };
        draft.accessToken = null;
        draft.refreshToken = null;
        break;
      case "SET_REDIRECT": {
        draft.redirectTo = action.value;
        break;
      }
      case "SET_ACCESS_TOKEN": {
        draft.accessToken = action.value;
        break;
      }
      case "ADD_NOTIFICATION": {
        draft.notifications.push(action.value);
        break;
      }
      case "REMOVE_NOTIFICATION": {
        if (action.value === -1) {
          draft.notifications.shift();
        } else {
          draft.notifications.splice(action.value, 1);
        }
        break;
      }
      case "SET_MIC_CHECK": {
        draft.checkedMic = action.value;
        break;
      }
      case "SET_ACTION_PENDING":
        draft.actionPending = action.value;
        break;
      case "SET_MIC_CHECK_DATE": {
        draft.micCheckedOn = action.value;
        break;
      }
      default:
        break;
    }
    return draft;
  });
}
