import React, { useEffect, useMemo } from "react";
import { Nav } from "react-bootstrap";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import Page from "../components/Page";
import { Button } from "../components/Button";
import { TextInput } from "../components/TextInput";
import { buildNotification, capitalize } from "../services/utils";
import { apiLogin, ILoginSuccess, apiSignup } from "../services/api";
import "../styles/login.css";

import { getAxiosError } from "../services/utils";
import useAuth from "../contexts/AuthContext";
import config from "../services/config";
import { useImmerReducer } from "use-immer";
import produce from "immer";

interface AuthPageState {
  userId: {
    value: string;
    hasErrors: boolean;
  };
  password: {
    value: string;
    hasErrors: boolean;
  };
  inviteCode: {
    value: string | null;
    hasErrors: boolean;
  };
  activeTab: "login" | "signup";
  submitCount: number;
}

type AuthPageAction =
  | {
      type: "CHANGE_TAB";
      value: "login" | "signup";
    }
  | {
      type: "USER_ID_CHANGE";
      value: string;
    }
  | {
      type: "PASSWORD_CHANGE";
      value: string;
    }
  | {
      type: "INVITE_CODE_CHANGE";
      value: string;
    }
  | {
      type: "FORM_SUBMIT";
    }
  | {
      type: "SET_INVITE_CODE_MESSAGE";
      value: string;
    };

interface AuthPageProps {}

const AuthPage: React.FC<AuthPageProps> = () => {
  const { state: authState, dispatch: authDispatch } = useAuth();
  const [searchParams] = useSearchParams();

  const initialState: AuthPageState = {
    userId: {
      value: "",
      hasErrors: false,
    },
    password: {
      value: "",
      hasErrors: false,
    },
    inviteCode: {
      value: "",
      hasErrors: false,
    },
    activeTab: "login",
    submitCount: 0,
  };

  if (searchParams && searchParams.get("invite") && searchParams.get("user")) {
    initialState.activeTab = "signup";
    initialState.inviteCode = {
      value: searchParams.get("invite"),
      hasErrors: false,
    };
    initialState.userId = {
      value: searchParams.get("user")!,
      hasErrors: false,
    };
  }

  const authPageReducer = (
    state: AuthPageState,
    action: AuthPageAction
  ): AuthPageState => {
    return produce(state, (draft) => {
      switch (action.type) {
        case "CHANGE_TAB": {
          draft.activeTab = action.value;
          draft.userId.value = "";
          draft.userId.hasErrors = false;
          draft.password.value = "";
          draft.password.hasErrors = false;
          draft.inviteCode.value = "";
          draft.inviteCode.hasErrors = false;
          break;
        }
        case "USER_ID_CHANGE": {
          draft.userId.value = action.value;
          draft.userId.hasErrors = false;
          draft.submitCount = 0;
          break;
        }
        case "PASSWORD_CHANGE": {
          draft.password.value = action.value;
          draft.password.hasErrors = false;
          draft.submitCount = 0;
          break;
        }
        case "INVITE_CODE_CHANGE": {
          draft.inviteCode.value = action.value;
          draft.inviteCode.hasErrors = false;
          draft.submitCount = 0;
          break;
        }
        case "FORM_SUBMIT": {
          draft.submitCount++;
          break;
        }
        default:
          break;
      }
      return draft;
    });
  };

  const [state, dispatch] = useImmerReducer(authPageReducer, initialState);

  // form submission hook
  useEffect(() => {
    const request = axios.CancelToken.source();

    // signup
    async function sendAuthSignup() {
      try {
        const payload = {
          userId: state.userId.value,
          password: state.password.value,
          inviteCode: state.inviteCode.value!,
        };

        await apiSignup(payload, request.token);
        authDispatch({
          type: "ADD_NOTIFICATION",
          value: buildNotification({
            text: "Your account is ready. Please login now!",
            notificationType: "success",
            heading: "Success",
          }),
        });

        dispatch({
          type: "CHANGE_TAB",
          value: "login",
        });
      } catch (e) {
        authDispatch({
          type: "ADD_NOTIFICATION",
          value: buildNotification({
            text: getAxiosError(e),
            notificationType: "danger",
            heading: "Signup error",
          }),
        });
      }
    }

    // login
    async function sendAuthLogin() {
      const payload = {
        userId: state.userId.value,
        password: state.password.value,
      };
      try {
        const { data } = await apiLogin(payload, request.token);
        const tokenResponse = data as ILoginSuccess;
        authDispatch({ type: "LOGIN", value: tokenResponse });
      } catch (e) {
        authDispatch({
          type: "ADD_NOTIFICATION",
          value: buildNotification({
            text: getAxiosError(e),
            notificationType: "danger",
            heading: "Login error",
          }),
        });
      }
    }

    if (state.submitCount && state.userId.value && state.password.value) {
      if (state.activeTab === "login") {
        sendAuthLogin();
      } else {
        sendAuthSignup();
      }
    }

    return () => request.cancel();
  }, [
    state.activeTab,
    state.submitCount,
    state.userId.value,
    state.password.value,
    state.inviteCode.value,
    authDispatch,
    dispatch,
  ]);

  // form validity hook
  // signup form will have
  const isFormValid = useMemo(() => {
    if (state.activeTab === "login") {
      if (state.password.value && state.userId.value) {
        return true;
      }
    } else if (state.activeTab === "signup") {
      if (
        state.password.value &&
        state.userId.value &&
        state.inviteCode.value
      ) {
        return true;
      }
    }
    return false;
  }, [
    state.password.value,
    state.userId.value,
    state.inviteCode.value,
    state.activeTab,
  ]);

  // form submission handler
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch({ type: "FORM_SUBMIT" });
  };

  return authState.isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <Page
      title={capitalize(state.activeTab)}
      heading={config.appName}
      noBg={true}
      subText="This research study is being supported by the University of Cambridge and Cambridgeshire and Peterborough NHS Foundation Trust"
    >
      <Nav
        variant="tabs"
        defaultActiveKey={state.activeTab}
        onSelect={(selectedKey) => {
          if (selectedKey === "login" || selectedKey === "signup") {
            dispatch({ type: "CHANGE_TAB", value: selectedKey });
          }
        }}
      >
        <Nav.Item className="w-50">
          <Nav.Link eventKey="login">
            <button className="tabs-font btn-bg" type="button">
              Login
            </button>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="w-50">
          <Nav.Link eventKey="signup">
            <button className="tabs-font btn-bg" type="button">
              Sign up
            </button>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="tab-content tabs-bg-color">
        {/* login and signup form starts */}
        <div
          className="tab-pane fade active show"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <form className="sm-create-form" onSubmit={(e) => handleSubmit(e)}>
            <TextInput
              type="text"
              label="User ID"
              id="user_id"
              placeholder="Enter your User ID"
              values={state.userId.value!}
              onChange={(e: any) =>
                dispatch({ type: "USER_ID_CHANGE", value: e.target.value })
              }
            />

            {state.activeTab === "signup" && (
              <TextInput
                type="text"
                label="Invite code"
                id="invite_code"
                placeholder="Enter your invite code"
                values={state.inviteCode.value!}
                onChange={(e: any) => {
                  dispatch({
                    type: "INVITE_CODE_CHANGE",
                    value: e.target.value,
                  });
                }}
              />
            )}

            <TextInput
              type="password"
              label={
                state.activeTab === "login" ? "Password" : "Create password"
              }
              placeholder="Enter password"
              addClass="fp-space"
              values={state.password.value!}
              onChange={(e: any) =>
                dispatch({ type: "PASSWORD_CHANGE", value: e.target.value })
              }
              icon="password"
              id="password"
            />

            {state.activeTab === "login" && (
              <div className="">
                <Link to="/auth/forgot-password">Forgot password</Link>
              </div>
            )}

            <div className="margin-bt">
              <Button
                buttonType="submit"
                text={state.activeTab === "login" ? "Login" : "Sign up"}
                addClass="primary-btn"
                disabled={!isFormValid}
              />
            </div>
          </form>
        </div>
        {/* login and signup form ends */}
      </div>
    </Page>
  );
};

export default AuthPage;
