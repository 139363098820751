import React from "react";

import { Link } from "react-router-dom";

import logo from "../assets/images/SpeechBox_White.png";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-top">
          <div>
            <Link to="/about-the-research">About the research</Link>
          </div>
          <div>
            <Link to="/terms-of-use">Terms of use</Link>
          </div>
          <div>
            <Link to="/privacy-policy">Privacy policy</Link>
          </div>
        </div>
        <div className="footer-bottom">
          <img src={logo} alt="Speechbox logo" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
