import React from "react";

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
  return (
    <>
      <div className="app-loading">Please wait ...</div>
    </>
  );
};

export default Loading;
