import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import axios, { CancelTokenSource } from "axios";

import {
  IActivitiesState,
  TActivitiesAction,
  initialState,
  activitiesReducer,
} from "../reducers/ActivitiesReducer";
import {
  apiGetWeekActivities,
  apiSendRecording,
  apiGetPastActivities,
} from "../services/api";
import { Audio, dbBulkUpsertActivities, dbSavedAudios } from "../services/db";
import { buildPastRecords } from "../services/utils";
import useAuth from "./AuthContext";

type ActivitiesContextProps = {
  state: IActivitiesState;
  dispatch: React.Dispatch<TActivitiesAction>;
};

const ActivitiesContext = createContext<ActivitiesContextProps>({
  state: initialState,
  dispatch: () => initialState,
});

export function ActivitiesProvider(props: PropsWithChildren<{}>) {
  const { state: authState, dispatch: authDispatch } = useAuth();
  const [state, dispatch] = useImmerReducer(activitiesReducer, initialState);

  // get saved items on first load
  useEffect(() => {
    async function getSaved() {
      const saved = await dbSavedAudios();
      dispatch({ type: "LOAD_AUDIOS_IN_DB", value: saved });
    }

    getSaved();
  }, [dispatch]);

  // updating for week in db
  useEffect(() => {
    async function writeActivitiesDB() {
      await dbBulkUpsertActivities(state.activitiesToAttempt);
    }
    if (state.activitiesToAttempt.length) {
      writeActivitiesDB();
    }
  }, [state.activitiesToAttempt]);

  // getting past activities
  useEffect(() => {
    const request = axios.CancelToken.source();

    async function fetchPastActivities() {
      try {
        const { data } = await apiGetPastActivities(request.token);
        if (data.attempted) {
          dispatch({
            type: "SET_PAST_ACTIVITIES",
            value: buildPastRecords(data.attempted),
          });
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (state.fetchPast) {
      fetchPastActivities();
    }

    return () => request.cancel();
  }, [state.fetchPast, dispatch]);

  // getting activities for the week
  useEffect(() => {
    const request = axios.CancelToken.source();

    async function fetchWeekActivities() {
      try {
        const { data } = await apiGetWeekActivities(request.token);
        dispatch({
          type: "SET_ACTIVITIES_TO_ATTEMPT",
          value: data.toAttemptThisWeek,
        });
        dispatch({
          type: "SET_ACTIVITIES_ATTEMPTED",
          value: data.attemptedThisWeek,
        });

        if (data.toAttemptThisWeek.length) {
          dispatch({
            type: "SET_NEXT_ACTIVITY",
            value: data.toAttemptThisWeek[0],
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        authDispatch({ type: "SET_ACTION_PENDING", value: false });
      }
    }

    if (state.fetchForWeek) {
      authDispatch({ type: "SET_ACTION_PENDING", value: true });
      fetchWeekActivities();
    }

    return () => request.cancel();
  }, [state.fetchForWeek, dispatch, authDispatch]);

  // sending to api once we have audios to send
  useEffect(() => {
    const requests: CancelTokenSource[] = [];

    async function sendAudiosToApi(audios: Audio[]) {
      try {
        for (const audio of audios) {
          const req = axios.CancelToken.source();
          requests.push(req);
          const audioBlob = new Blob([audio.audio!], {
            type: "audio/ogg; codecs=opus",
          });
          const audioFile = new File(
            [audioBlob],
            `${audio.activityId}__${authState.user.userId}.ogg`
          );
          const payload = {
            activityId: audio.activityId,
            file: audioFile,
            recordingOrder: audio.recordingOrder?.toString(),
          };
          await apiSendRecording(payload, req.token);
          dispatch({ type: "MARK_AUDIO_UPLOADED", value: audio.activityId });
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (state.sendAudios && state.audiosInDB.length) {
      sendAudiosToApi(state.audiosInDB);
    }

    return () => {
      if (requests.length) {
        for (const req of requests) {
          req.cancel();
        }
      }
    };
  }, [state.sendAudios, state.audiosInDB, authState.user, dispatch]);

  return <ActivitiesContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function useActivities() {
  return useContext(ActivitiesContext);
}
