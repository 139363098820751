import React from "react";
import useAuth from "../contexts/AuthContext";

interface HeaderProps {
  title?: string;
  subText?: string;
}

const Header: React.FC<HeaderProps> = ({ title, subText }) => {
  const { dispatch: authDispatch, state: authState } = useAuth();

  return (
    <div className="header-wrap">
      <div className="inner-container no-neg-mar">
        <div className="header-wrap-sm content">
          {authState.isLoggedIn && (
            <div className="text-right">
              <button
                onClick={() => authDispatch({ type: "LOGOUT" })}
                className="danger-btn-clear font-weight-bold clear-btn"
              >
                Logout
              </button>
            </div>
          )}
          <div>
            <h1>{title}</h1>
            {subText && <p className="font-color">{subText}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
