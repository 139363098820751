import React from "react";

interface ContainerProps {
  noBg?: Boolean;
}

const Container: React.FC<ContainerProps> = (props) => {
  return (
    <div className={`outter-container ${props.noBg ? "no-bg" : ""}`}>
      {props.children}
    </div>
  );
};

export default Container;
