import React from "react";
import useAuthContext from "../contexts/AuthContext";
import { Toast, Spinner, ToastContainer } from "react-bootstrap";

interface LoadingToastProps {}

const Loading: React.FC<LoadingToastProps> = () => {
  const { state: authState } = useAuthContext();

  return (
    <ToastContainer className="p-3" position="middle-center">
      <Toast show={authState.actionPending}>
        <Toast.Body>
          <div className="d-flex">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading</span>
            </Spinner>
            <div className="m-2">Please wait</div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Loading;
