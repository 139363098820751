import moment from "moment";
import {
  IPastActivity,
  IUserActivity,
  IUserNotification,
  TAppColorAccents,
} from "../types";

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// https://github.com/axios/axios#handling-errors
export const getAxiosError = (e: any): string => {
  let message = "Unknown error while contacting service";
  if (e.response) {
    // Request made and server responded
    if (e.response.data && e.response.data.message) {
      message = e.response.data.message;
    }
  } else if (e.request) {
    // The request was made but no response was received
    message = "Did not receive response from service";
  } else {
    // Something happened in setting up the request that triggered an Error
    message = e.message;
  }
  return message;
};

export const buildNotification = ({
  text,
  notificationType,
  heading,
}: {
  text: string;
  notificationType: TAppColorAccents;
  heading: string;
}): IUserNotification => {
  return {
    text: text,
    notificationType,
    heading,
    datetime: new Date(),
    id: new Date().getTime(),
  };
};

export const timeAgo = (date: Date) => {
  const now = new Date();
  const nowMoment = moment(now);
  const pastMoment = moment(date);
  const timeAgoString = pastMoment.from(nowMoment); // 2 hours ago
  return timeAgoString;
};

export const timeFormatting = (n: number): string => {
  if (n < 10) {
    return `0${n}`;
  } else {
    return `${n}`;
  }
};

export const buildPastRecords = (records: IUserActivity[]): IPastActivity[] => {
  const _records: IPastActivity[] = [];
  for (const r of records) {
    const week = moment(r.createdAt).startOf("isoWeek").toDate();
    const weekKey = moment(r.createdAt).startOf("isoWeek").format("YYYY-MM-DD");

    const idx = _records.findIndex((_r) => _r.weekKey === weekKey);
    if (idx !== -1) {
      _records[idx].activities.push(r);
    } else {
      _records.push({
        week,
        weekKey,
        activities: [r],
      });
    }
  }
  return _records;
};
